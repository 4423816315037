import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['rrp', 'rrpContainer', 'tradePrice', 'tradePriceContainer', 'quantity', 'tradeDiscount', 'totalCost', 'markup', 'clientPrice',
    'clientSavings', 'profit', 'sectionTotal']

  connect() {
    // this.markup = this.financialViewSwitchController.markup
    // this.financialView = this.financialViewSwitchController.financialView
    this.element[this.element.id] = this;
    this.scheduleSectionId = this.rrpTarget.getAttribute('data-schedule-section-id')
    if(this.financialView) {
      this.toggleFinancialSections(this.financialView)
      this.financialViewSwitchController.toggleSectionTotalView()
    }
    // if(this.markup) {
    //   this.renderMarkupSectionsView(this.markup)
    // }
    this.setDesignerPriceDisplay()

  }

  renderMarkupSectionsView(markupFlag) {
    let rootElement = this.element
    rootElement.querySelectorAll('.markup-section').forEach(function (markupSection){
      markupSection.classList.toggle('hidden', !markupFlag)
    })
    rootElement.querySelector('.designer-total').innerHTML = markupFlag ? 'Cost' : 'Total'
    rootElement.querySelector('.financial-item-total-container').classList.toggle('border-left-highlight', !markupFlag)
  }

  toggleFinancialSections(financialViewFlag) {
    let rootElement = this.element
    let financialTarget = rootElement.querySelector('.financial')
    let defaultTarget = rootElement.querySelector('.default')
    financialTarget.classList.toggle('hidden', !financialViewFlag)
    defaultTarget.classList.toggle('hidden', financialViewFlag)
  }

  setDesignerPriceDisplay() {
    let tradePrice = this.tradePriceTarget.value
    let tradePriceExists = !(tradePrice == undefined || tradePrice == "")
    let rrpContainer = this.rrpContainerTarget
    let tradePriceContainer = this.tradePriceContainerTarget
    rrpContainer.classList.toggle('translate-y-10', tradePriceExists)
    tradePriceContainer.classList.toggle('-translate-y-10', tradePriceExists)
    rrpContainer.classList.toggle('opacity-25', tradePriceExists)
    tradePriceContainer.classList.toggle('opacity-25', !tradePriceExists)
  }

  getDesignerFinancials() {
    this.setDesignerPriceDisplay()
    let rrp = this.rrpTarget.value
    let tradePrice = this.tradePriceTarget.value
    let quantity = this.quantityTarget.value
    let markup = parseFloat(this.markupTarget.value)
    markup = isNaN(markup) ? 0 : markup
    let showArchived = this.financialViewSwitchController.showArchivedItems()

    let url = '/schedules2/schedule_section_items/'+this.scheduleSectionId+'/financials'
    let inputData = {
      rrp: rrp,
      trade_price: tradePrice,
      quantity: quantity,
      markup: markup,
      show_archived: showArchived
    }
    let designerFinancialData
    this.fetchData(url, 'get', inputData).then(response => {
      designerFinancialData = response
      this.setTradeDiscount(designerFinancialData)
      this.setTotalCost(designerFinancialData)
      this.setClientPrice(designerFinancialData)
      this.setClientSavings(designerFinancialData)
      this.setProfit(designerFinancialData)
      this.setSectionTotal(designerFinancialData)
      this.setScheduleTotal(designerFinancialData)
    })
  }

  setProfit(clientFinancialData) {
    if(!clientFinancialData) { return null }

    let profit = String(clientFinancialData.profit)
    this.setValue(this.profitTarget, profit)

    this.propagateChange(this.profitTarget)
  }

  setClientPrice(clientFinancialData) {
    if(!clientFinancialData) { return null }

    let clientPrice = String(clientFinancialData.client_price)
    this.setValue(this.clientPriceTarget, clientPrice)

    this.propagateChange(this.clientPriceTarget)
  }

  setClientSavings(clientFinancialData){
    if(!clientFinancialData) { return null }

    let clientSavings = String(clientFinancialData.client_savings)
    this.setValue(this.clientSavingsTarget, clientSavings)
    this.propagateChange(this.clientSavingsTarget)
  }

  setTradeDiscount(designerFinancialData) {
    if(!designerFinancialData) { return null }

    let tradeDiscount = designerFinancialData.trade_discount
    this.setValue(this.tradeDiscountTarget, tradeDiscount)

    this.propagateChange(this.tradeDiscountTarget)
  }

  setTotalCost(designerFinancialData) {
    if(!designerFinancialData) { return null }

    let totalCost = String(designerFinancialData.cost)
    this.setValue(this.totalCostTarget, totalCost)

    this.propagateChange(this.totalCostTarget)
  }

  setSectionTotal(designerFinancialData) {
    let scheduleSectionTarget = this.element.closest('.schedule-section')
    let sectionDesignerTotalTarget = scheduleSectionTarget.querySelector('.section-total-designer')
    let sectionClientTotalTarget = scheduleSectionTarget.querySelector('.section-total-client')

    let sectionTotalDesignerCost = this.getCurrencyValue(designerFinancialData.section_total_designer_cost)
    let sectionTotalClientPrice = this.getCurrencyValue(designerFinancialData.section_total_client_price)

    this.setHtml(sectionDesignerTotalTarget, sectionTotalDesignerCost)
    this.setHtml(sectionClientTotalTarget, sectionTotalClientPrice)
  }

  setScheduleTotal(designerFinancialData) {
    let scheduleDesignerTotalTarget = document.querySelector('.schedule-total-designer')
    let scheduleClientTotalTarget = document.querySelector('.schedule-total-client')

    let scheduleTotalDesignerCost = this.getCurrencyValue(designerFinancialData.schedule_total_designer_cost)
    let scheduleTotalClientPrice = this.getCurrencyValue(designerFinancialData.schedule_total_client_price)

    this.setHtml(scheduleDesignerTotalTarget, scheduleTotalDesignerCost)
    this.setHtml(scheduleClientTotalTarget, scheduleTotalClientPrice)
  }

  setValue(target, value) {
    if(isNaN(value) || value === 0){ value = null }
    target.value = value
  }

  setHtml(target, content) {
    target.innerHTML = content
  }

  getCurrencyValue(amount) {
    return ((amount == undefined || amount === 0) ? "" :
      parseFloat(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
  }

  fetchData(url, method, inputData){
    return fetch(url + '?' + new URLSearchParams(inputData), {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrf_token
      }
    })
      .then((response) => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
      })
  }

  propagateTotalsChange () {
    this.propagateChange(this.clientPriceTarget)
  }

  propagateChange(target) {
    let event = new Event('change')
    target.dispatchEvent(event)
  }

  get financialViewSwitchController() {
    return document.querySelector('#financialViewSwitch').financialViewSwitch
  }
}
